import {
	Icon,
	Collapse,
	CollapseItem
} from 'vant';
import {
	commitIndustrySelect
} from '@/api/index.js';

export default {
	components: {
		[Icon.name]: Icon,
		[Collapse.name]: Collapse,
		[CollapseItem.name]: CollapseItem,
	},
	data() {
		return {
			activeNames: [], // 展开项
		}
	},
	created() {
		// 设置展开项
		let idx = String(this.data.selected).charAt(0) || ''; // 取选中项第一个字符
		this.activeNames = idx ? [+idx] : [];
	},
	methods: {
		choose(id) {
			this.$toast.loading({
				loadingType: 'spinner',
				forbidClick: true,
				duration: 0,
			});
			
			this.data.selected = id;
			
			let params = {
				id,
				unionid: this.unionid
			};
			
			commitIndustrySelect(params).then(res => {
				this.setPageData(this.path, this.data); // 更新该页的回填数据
				this.pageSwitch(res.data)
			}).catch(err => {
				err ? this.$toast(err.message) : this.$toast.clear();
			});
		},
	}
}
